import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'

const Production = () => {

    const data = useStaticQuery(graphql`
          {
            allContentfulProductionDepartment(sort: {order:ASC , fields: updatedAt}) {
              nodes {
                title
                photo {
                  gatsbyImageData
                }
              }
            }
          }
        `)

    return (
        <>
            <Helmet
                title="制作部ページ - マコム・プラニング公式サイト"
                description="マコム・プラニングの制作部に関するページです。過去制作物のギャラリーや、事業部の理念などをご確認いただけます。"
            />
            <Layout>
                {/* Hero Part */}
                <section className="relative py-40">
                    <div className="absolute top-0 left-0 lg:w-4/5 w-full h-full bg-orange-50/50 rounded-r-full" />
                    <div className="relative container px-4 h-full mx-auto">
                        <div className="relative flex flex-wrap items-center justify-center -mx-4">
                            <div className="relative z-10 w-full md:w-auto lg:w-3/5 px-4">
                                <div className="mx-auto text-center">
                                    <span className="text-orange-400 font-semibold">Production Department</span>
                                    <h1 className="mt-8 mb-6 lg:mb-10 text-4xl font-semibold">制作事業部のご案内</h1>
                                    <h2 className="mb-8 lg:mb-12 text-gray-600">macom planning制作事業部は、スポーツアパレル、ファッション分野を中心に、グローバルなクリエイティブワークを提供するブランディング・エージェンシーです。</h2>
                                    <Link id='enquiry' className="px-10 py-4 text-sm leading-normal ring-1 hover:ring-0 ring-slate-600 text-slate-600 hover:bg-orange-400 hover:text-white duration-500 rounded-full" to="/#enquiry">お問い合わせはこちら</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Gallery Section Head */}
                <section className="pt-20">
                    <div className="container px-4 mx-auto mb-16">
                        <div className="max-w-2xl mx-auto text-center">
                            <div className="flex mb-4 items-center justify-center text-orange-400 font-semibold">Gallery</div>
                            <h2 className="mb-10 text-4xl font-semibold font-heading">制作物紹介</h2>
                        </div>
                    </div>
                </section>



                {/* API based gallery */}
                <section className="pb-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap -m-3">
                            {data.allContentfulProductionDepartment.nodes.map((g, index) => {
                                return (
                                    <div className="w-full lg:w-1/2 p-3" key={index}>
                                        <GatsbyImage className="w-full h-max rounded-lg object-cover shadow-md hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6" image={getImage(g.photo.gatsbyImageData)} alt={g.title} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>


            </Layout>
        </>
    )
}

export default Production
